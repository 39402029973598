/*
palette: https://palettes.shecodes.io/palettes/1313
*/

* {
  text-transform: uppercase;
}

.App {
  text-align: center;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 720px;
    justify-content: center;

    .topbar {
      background: #142d4c;
      position: sticky;
      top: 0;
      width: 100%;

      .word-combo {
        display: flex;
        height: 50px;
        justify-content: center;
        margin-bottom: 16px;
        min-height: 40px;

        .spinner {
          animation: spin 2s linear infinite;
          border: 10px solid #f3f3f3;
          border-top: 10px solid #3498db;
          border-radius: 50%;
          display: inline-block;
          height: 0.5vw;
          margin-left: 10px;
          max-height: 100%;
          padding: 10px;
          width: 0.5vw;
        }
      }
    }

    .game-buttons-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;

      .draggable {
        .game-button {
          cursor: move;
        }
        cursor: move;
      }

      .game-button {
        min-height: 104px;
        min-width: 64px;
        padding: 0px 6px 0px 6px;
        text-transform: lowercase;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button {
  /* background-color: #baadd3; */
  background-color: #9fd3c7;
  /* box-shadow: 0px 0px 1px 2px #0ff; */
  box-shadow: 0px 0px 1px 2px #385170;
  display: flex-inline;
  font-weight: bold;
  font-size: large;
  justify-content: space-between;
  margin: 4px;
}

button:hover {
  background-color: #ffffff;
  color: #671199;
  font-weight: bold;
}

body {
  background: #142d4c;
  color: #ececec;
  /* font-size: 16px; */
  font-size: calc(10px + 2vmin);
}

span.word {
  padding: 4px;
}

/* Define the animation */
@keyframes tada {
  0% {
    color: white;
    transform: scale(1);
    /* Original size */
  }

  25%,
  75% {
    color: #ffd700;
    transform: scale(1.1);
    /* Grow a bit */
  }

  50% {
    color: white;
    transform: scale(0.9);
    /* Shrink a bit */
  }

  100% {
    color: #ffd700;
    transform: scale(1);
    /* Original size */
  }
}

/* Apply the animation to span elements */
span.firstWord {
  /* Needed for transform */
  animation: tada 1s ease 1;
  color: #ffd700;
  /* Adjust time and easing as needed */
}
